const TimeOff = {
  timeOff: {
    title: "Tiempo Libre",
    user: "Usuario",
    team: "Equipo",
    accumulated: "Acumulado",
    requested: "Solicitado",
    pending: "Pendiente",
    all: "Todos",
    category: "Categoría",
    status: "Estado",
    approved: "Aprobado",
    rejected: "Rechazado",
    history: "Historial de solicitudes",
    freeDays: "Días libres",
    noFreeDays: "No hay politicas de tiempo libre",
    noHistory: "No hay historial de solicitudes",
    detailTitle: "Detalle de uso de días libres",
    headersDays: {
      category: "Categoría",
      politic: "Política",
      solicited: "Solicitado",
      accumulated: "Acumulado",
    },
    headersHistory: {
      date: "Fecha",
      politic: "Política",
      solicited: "Solicitado",
      approved: "Aprobador",
      state: "Estado",
    },
    filter: {
      team: "Filtrar por equipo",
      policy: "Filtrar por política",
    },
    tabs: {
      all: "Todos",
      admin: "Administrativo",
      legal: "Legal",
      additional: "Adicional",
    },
  },
};

export default TimeOff;
