/* eslint-disable no-param-reassign */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Suspense, useEffect } from "react";
import PropTypes from "prop-types";
import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

// Redux
import { ThemeProvider } from "@material-ui/styles";
import { clientAxios as axios } from "./apiClients";
import GlobalStyles from "./theme/globalStyles";
import theme from "./theme";

import LoadingPage from "./components/Fallback/LoadingPage";


import AppRoutes from "./AppRoutes";

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const AuthzWrapper = ({ children, ...props }) => {
  const { getAccessTokenSilently, user } = useAuth0();

  axios.interceptors.request.use(
    async (config) => {
      try {
        const token = await getAccessTokenSilently();
        if (token && user) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } catch (error) {
        console.debug("Error fetching Auth0 token:", error);
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  return <>{children}</>;
};

const App = function ({ pca }) {
  return (
    <Auth0ProviderWithRedirectCallback
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/admin/dashboard`,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "profile email read:users",
      }}
    >
      <AuthzWrapper>
        <MsalProvider instance={pca}>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <Suspense fallback={<LoadingPage />}>
                <AppRoutes />
              </Suspense>
            </ThemeProvider>
          </LocalizationProvider>
        </MsalProvider>
      </AuthzWrapper>
    </Auth0ProviderWithRedirectCallback>
  );
};

App.propTypes = {
  pca: PropTypes.instanceOf(PublicClientApplication).isRequired,
};

export default App;
