import { getI18n } from "react-i18next";
import { OPEN_INFO, CLOSE_INFO } from "../actions/types";

const i18n = getI18n();

const initialState = {
  open: false,
  type: undefined,
  text: undefined,
};

export default function infoReducers(state = initialState, action) {
  switch (action.type) {
    case OPEN_INFO:
      return {
        ...state,
        type: action.payload.type ?? "error",
        text: action.payload.text ?? i18n.t("infoModal.message.error"),
        open: true,
      };
    case CLOSE_INFO:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
