const sidebar = {
  sidebar: {
    dashboard: "Dashboard",
    users: "Users",
    teams: "Teams",
    surveys: "Surveys",
    feedbacks: "Feedbacks",
    settings: "Settings",
    questions: "Questions",
    privacity: "Privacy",
    recognitions: "Recognitions",
    timeOff: "Time Off",
  },
};

export default sidebar;
