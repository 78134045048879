import React, { useEffect, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { withAuthenticationRequired } from "@auth0/auth0-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast, Toaster } from "react-hot-toast";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Loader from "./utils/Loader";

const NotFoundView = lazy(() => import("./components/NotFoundView"));
const AdminOnboarding = lazy(() => import("./components/Admin/Onboarding"));
const Onboarding = lazy(() => import("./components/User/Onboarding"));
const AdminDashboardLayout = lazy(() =>
  import("./components/AdminDashboardLayout")
);
const OnboardingTeamsConnect = lazy(() =>
  import("./components/Admin/Teams/OnboardingTeamsConnect")
);
const OnboardingTeamsAdminConsent = lazy(() =>
  import("./components/Admin/Teams/OnboardingTeamsAdminConsent")
);
const SurveyAnswer = lazy(() => import("./components/SurveyAnswer"));
const Feedback = lazy(() => import("./components/Feedback/Feedback"));
const BozPasswordChanged = lazy(() =>
  import("./components/ResetPassword/BozPasswordChanged")
);
const BozChangePassword = lazy(() =>
  import("./components/ResetPassword/BozChangePassword")
);
const BozPasswordReseted = lazy(() =>
  import("./components/ResetPassword/BozPasswordReseted")
);
const BozResetPassword = lazy(() =>
  import("./components/ResetPassword/BozResetPassword")
);
const EmailSurvey = lazy(() => import("./components/EmailSurvey/EmailSurvey"));
const UserParticipation = lazy(() =>
  import("./components/UserParticipation/UserParticipation")
);
const RecognitionsReport = lazy(() =>
  import("./components/Recognitions/RecognitionsReport")
);
const RecognitionsRequest = lazy(() =>
  import("./components/Recognitions/RecognitionsRequest")
);

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

// Custom wrapper for toast with warning type
toast.warning = (message, props) => {
  toast(message, {
    ...props,
    icon: "⚠️",
    style: {
      border: "1px solid #E3E164",
      background: "#F1F0B1",
      color: "#74701A",
    },
  });
};

toast.info = (message, props) => {
  toast(message, {
    ...props,
    icon: "ℹ️",
    style: {
      border: "1px solid #64CAE3",
      background: "#C8ECF5",
      color: "#271A74",
    },
  });
};

const AppRoutes = React.memo(() => {
  const info = useSelector((state) => state.info);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();

  useEffect(() => {
    const { open, type, text } = info;
    if (open) {
      dispatch({ type: "CLOSE_INFO" });
      toast[type](text || "Error");
    }
  }, [info, dispatch]);

  useEffect(() => {
    let unSubscribe = false;
    if (!unSubscribe && loader.open) {
      setTimeout(() => {
        dispatch({ type: "CLOSE_LOADER" });
      }, 10000);
    }
    return () => {
      unSubscribe = true;
    };
  }, [loader, dispatch]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
        <Route
          path="/admin/*"
          element={<ProtectedRoute component={AdminDashboardLayout} />}
        />
        <Route path="/reset-password" element={<BozResetPassword />} />
        <Route path="/password-reseted" element={<BozPasswordReseted />} />
        <Route path="/password-changed" element={<BozPasswordChanged />} />
        <Route path="/change-password" element={<BozChangePassword />} />
        <Route path="/survey/:id" element={<SurveyAnswer />} />
        <Route path="/onboarding/:invitation" element={<Onboarding />} />
        <Route path="/daily-survey/:token" element={<EmailSurvey />} />
        <Route path="/account_activation/:id" element={<AdminOnboarding />} />
        <Route
          path="/onboarding-connect-to-teams"
          element={<OnboardingTeamsConnect />}
        />
        <Route
          path="/admin-consent-teams"
          element={<OnboardingTeamsAdminConsent />}
        />
        <Route
          path="/user-participation/:token"
          element={<UserParticipation />}
        />
        <Route path="/feedback" element={<Feedback />} />
        <Route
          path="/recognitions-report/:id"
          element={<RecognitionsReport />}
        />
        <Route path="/recognitions-request" element={<RecognitionsRequest />} />
        <Route path="/linkedin" element={<LinkedInCallback />} />
        <Route path="*" element={<NotFoundView />} />
      </Routes>
      {loader && loader.open && <Loader />}
      <Toaster
        position="top-center"
        gutter={24}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            padding: "12px 26px",
            width: "450px", // Set a specific width (e.g., 600px) or use percentages like "90%"
            maxWidth: "600px", // Optional: Prevent the toaster from overflowing on smaller screens
          },
          success: {
            style: {
              border: "1px solid #64E3A1",
              background: "#D1FEE7",
              color: "#1A7445",
            },
          },
          error: {
            style: {
              border: "1px solid#E36464",
              background: "#F1B1B1",
              color: "#741A1A",
            },
          },
        }}
      />
    </>
  );
});

// Assign display name for better debugging and ESLint compliance
AppRoutes.displayName = "AppRoutes";

export default AppRoutes;
