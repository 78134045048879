const sidebar = {
  sidebar: {
    dashboard: "Dashboard",
    users: "Usuários",
    teams: "Equipes",
    surveys: "Pesquisas",
    feedbacks: "Feedbacks",
    questions: "Perguntas",
    settings: "Configurações",
    privacy: "Privacidade",
    recognitions: "Reconhecimentos",
    timeOff: "Tempo Livre",
  },
};

export default sidebar;
